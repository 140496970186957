import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
// import { seedDatabase } from '../seed';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBG5SIZTVlgjM51nho-mYnUcvBgmLFDwGU",
  authDomain: "solar-co-ua.firebaseapp.com",
  projectId: "solar-co-ua",
  storageBucket: "solar-co-ua.appspot.com",
  messagingSenderId: "690354189806",
  appId: "1:690354189806:web:e565577a36d4d8aa238aff",
  measurementId: "G-TNQLCHLP8F"
};
firebase.initializeApp(firebaseConfig);

export { firebase };
