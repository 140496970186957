import * as React from "react";
import { CompoundInterest } from "../containers/calculator";

export default function Calculatorr() {
  return (
    <>
      <CompoundInterest />;
    </>
  );
}
